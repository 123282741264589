<script setup>
const projectScores = [
  {
    name: 'Flovatars',
    score: '794',
    icon: 'https://flovatar.com/favicon.ico',
  },
  {
    name: 'Hoodlums',
    score: '641',
    icon: 'https://hoodlumsnft.com/hl.ico',
  },
  {
    name: 'Bobblz',
    score: '279',
    icon: 'https://bobblz.io/favicon-32x32.png',
  },
]
</script>

<template>
  <VCard>
    <VCardItem>
      <VCardTitle>Project Leaderboard</VCardTitle>
    </VCardItem>

    <VCardText>
      <VList class='card-list'>
        <VListItem
          v-for='data in projectScores'
          :key='data.name'
        >
          <template #prepend>
            <VAvatar
              size='40'
              variant='tonal'
              class='me-3'
            >
              <VImg :src='data.icon' />
            </VAvatar>
          </template>

          <VListItemSubtitle class='text-xs'>
            {{ data.name }}
          </VListItemSubtitle>

          <template #append>
            <div>
              <h4 class='font-weight-semibold'>
                {{ data.score }}
              </h4>
            </div>
          </template>
        </VListItem>
      </VList>
    </VCardText>
  </VCard>
</template>

<style lang='scss' scoped>
.card-list {
  --v-card-list-gap: 1.5rem;
}
</style>
