<script setup>
</script>

<template>
  <VRow class='match-height'>
    <VCol
      cols='12'
    >
      <VCard>
        <VCardText class='text-white'>
          <h2 class='text-center text-white mx-auto'>Store Coming Soon...</h2>
          <p class='text-white text-center mt-2'>Buy, create and sell your own "FlowSuits" and in game accessories.</p>
          <VImg class='text-center text-white mx-auto mt-4' max-width='600px'
                src='https://flowsgottalent.com/fgt-before-after.png' />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>
