<script setup>
const playerScores = [
  {
    'name': 'Kate Allen',
    'score': 194,
  },
  {
    'name': 'Emily Davis',
    'score': 178,
  },
  {
    'name': 'John Doe',
    'score': 171,
  },
  {
    'name': 'Alice Thompson',
    'score': 158,
  },
  {
    'name': 'Ashley Martin',
    'score': 137,
  },
  {
    'name': 'Mike Johnson',
    'score': 130,
  },
  {
    'name': 'Mark Garcia',
    'score': 107,
  },
  {
    'name': 'Chris Lee',
    'score': 93,
  },
  {
    'name': 'Jane Smith',
    'score': 77,
  },
  {
    'name': 'David Wilson',
    'score': 34,
  },
]

function getRandomColor(name) {
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase()

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0)

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString()

  var num = Math.round(0xffffff * parseInt(colorNum))
  var r = num >> 16 & 255
  var g = num >> 8 & 255
  var b = num & 255

  return {
    color: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)',
    character: firstAlphabet.toUpperCase(),
  }
}
</script>


<template>
  <VCard>
    <VCardItem>
      <VCardTitle>Player Leaderboard</VCardTitle>
    </VCardItem>

    <VCardText>
      <VList class='card-list'>
        <VListItem
          v-for='data in playerScores'
          :key='data.name'
        >
          <template #prepend>
            <VAvatar
              size='40'
              variant='tonal'
              class='me-3'
              :style='{ backgroundColor: getRandomColor(data.name).color }'
            >
              <VImg :src='`https://ui-avatars.com/api/?name=${data.name}&background=random`' />
            </VAvatar>
          </template>
          <VListItemSubtitle class='text-xs'>
            {{ data.name }}
          </VListItemSubtitle>

          <template #append>
            <div>
              <h4 class='font-weight-semibold'>
                {{ data.score }}
              </h4>
            </div>
          </template>
        </VListItem>
      </VList>
    </VCardText>
  </VCard>
</template>

<style lang='scss' scoped>
.card-list {
  --v-card-list-gap: 1.5rem;
}
</style>
