<script setup>
let script = document.createElement('script')
script.src = 'https://embed.typeform.com/next/embed.js'
document.body.appendChild(script)
</script>

<template>
  <VRow class='match-height'>
    <VCol
      cols='12'
    >
      <VCard>
        <VCardText class='text-white'>
          <h2 class='text-center text-white mx-auto ma-5'>Contact Us</h2>
          <v-row>
            <v-col cols='12' class='col-md-4'>
              <div data-tf-widget='feevSVFL' data-tf-opacity='100' data-tf-iframe-props="title=Flow's Got Talent"
                   data-tf-transitive-search-params data-tf-medium='snippet' style='width:100%;height:500px;'></div>
            </v-col>
          </v-row>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>
